import React from 'react';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


import NavBar from '../NavBar';

export default function Headers(props : any) {

  return (
        <NavBar/>
  );
}



















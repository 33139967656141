import React, { FC } from "react";
import { motion, Variants } from "framer-motion";

import Image from "../Image";
import Box from "@mui/material/Box";

interface LoaderProps {
  setLoading: (loading: boolean) => void;
}

const container: Variants = {
  show: {
    transition: {
      staggerChildren: 0.35,
    },
  },
};

const item: Variants = {
  hidden: { opacity: 0, y: 200 },
  show: {
    opacity: 1,
    y: 200,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1.6,
    },
  },
  exit: {
    opacity: 0,
    y: -200,
    transition: {
      ease: "easeInOut",
      duration: 0.8,
    },
  },
};

const itemMain: Variants = {
  hidden: { opacity: 0, y: 400 },
  show: {
    opacity: 1,
    y: 200,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1.6,
    },
  },
};

const Loader: FC<LoaderProps> = ({ setLoading }) => {
  return (
    <div>
    <motion.div className="loader">
      <motion.div
        variants={container}
        onAnimationComplete={() => setLoading(false)}
        initial="hidden"
        animate="show"
        exit="exit"
        className="loader-inner"
      >
        <ImageBlock variants={item} id="image-1" />
        <motion.div variants={itemMain} className="transition-image">
          <motion.img
            layoutId="main-image-1"
            src={process.env.PUBLIC_URL + `/images/image-2.jpg`}
          />
        </motion.div>
        <ImageBlock variants={item} id="image-3" />
        <ImageBlock variants={item} id="image-4" />
        <ImageBlock variants={item} id="image-5" />
      </motion.div>
    </motion.div>

    <Box height={450}>
    </Box>
    </div>
  );
};

interface ImageBlockProps {
  posX?: number;
  posY?: number;
  variants: Variants;
  id: string;
}

export const ImageBlock: FC<ImageBlockProps> = ({
  posX,
  posY,
  variants,
  id,
}) => {
  return (
    <motion.div
      variants={variants}
      className={`image-block ${id}`}
      style={{
        top: `${posY}vh`,
        left: `${posX}vw `,
      }}
    >
      <Image
        src={process.env.PUBLIC_URL + `/images/${id}.webp`}
        fallback={process.env.PUBLIC_URL + `/images/${id}.jpg`}
        alt={id}
      />
    </motion.div>
  );
};

export default Loader;